<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center flex-wrap">
      <strong>{{ detail.id }}</strong>
      <SMessageOrderStatus :id="detail.status_id" class="ml-1" />
      <SMessageIkomOrderStatus
        v-if="['Auction', 'Retail'].includes(detail.type_id)"
        :id="detail.primary_status"
        class="ml-1"
      />
      <TSpinner :loading="loading" />
      <div class="ml-auto d-flex align-items-center" v-if="showBtn">
        <SButtonStatus
          v-for="status in detail.steps_back"
          :key="status.id"
          :id="status"
          @click="updateOrder('previous_status', status)"
          :disabled="updating"
        />
        <CIcon
          v-if="!lodash.isEmpty(detail.steps_back)"
          name="cil-action-undo"
          class="mx-2"
          style="transform: rotate(90deg)"
        />
        <SButtonStatus
          v-for="status in detail.steps_forward"
          :key="status.id"
          :id="status"
          @click="
            status == 'Debited'
              ? (showDebitConfirm = true)
              : updateOrder('next_status', status)
          "
          :disabled="updating"
        />
        <TModalConfirm
          class="text-dark"
          :show.sync="showDebitConfirm"
          @click-confirm="nextStatusDebited"
          content="Are you sure you want to debit?"
        />
        <div v-if="detail.status_id == 'Debited'" class="d-flex">
          <span class="ml-1 my-auto">|</span>
          <SButtonDebit
            @click="debit"
            class="ml-1"
            :options="{ disabled: loading }"
          />
        </div>
        <span v-if="$slots['actions']" class="mx-1">|</span>
        <slot name="actions"> </slot>
        <TButtonRefresh
          class="ml-1"
          @click="refresh"
          :options="{ disabled: loading }"
        />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow class="mb-4">
        <CCol sm="12" md="6" lg="6" :xl="{ size: '4', order: '1' }">
          <TTableAsForm
            title="Details"
            :data="detail"
            :fields="detailFields"
            class="mb-3"
            :splitLeft="7"
          >
            <template #customer_id="{ value }">
              <TLink
                v-if="detail.customer"
                :to="lodash.getReferenceLink('user', value)"
                :content="detail.customer.name"
              />
            </template>
            <template #shipment_method_id="{ value }">
              <SMessageShipmentMethod
                :id="value"
                :editable="!updating"
                @change="updateOrder('shipment_method_id', $event)"
              />
            </template>
            <template #trackings="{ value }">
              <SMessageTracking
                v-if="!lodash.isEmpty(value)"
                :value="value[0].code"
                :checked="value[0].received"
                removable
                @click-remove="detachTracking(value[0].id)"
              />
              <TMessage
                v-else
                creatable
                @click-create="showAddTracking = true"
              />
            </template>
            <template #special_declaration="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
                :editable="!updating"
                @change="updateOrder('special_declaration', $event)"
              />
            </template>
            <template #special_goods_fee="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
            <template #insurance_declaration="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
                :editable="!updating"
                @change="updateOrder('insurance_declaration', $event)"
              />
            </template>
            <template #insurance_fee="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
            <template #note="{ value }">
              <TMessageTextarea
                :value="value"
                :editable="!updating"
                @change="updateOrder('note', $event)"
              />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol sm="12" md="6" lg="6" :xl="{ size: '4', order: '4' }">
          <TTableAsForm
            title="Requests"
            :data="detail"
            :fields="requestFields"
            class="mb-3"
            :splitLeft="7"
          >
            <template #repackage="{ value }">
              <TSwitch
                :checked="value"
                :disabled="updating"
                @change="updateOrder('repackage', $event)"
              />
            </template>
            <template #merge_package="{ value }">
              <TSwitch
                :checked="value"
                :disabled="updating"
                @change="updateOrder('merge_package', $event)"
              />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol sm="12" md="6" lg="6" :xl="{ size: '4', order: '2' }">
          <TTableAsForm
            title="Purchase cost"
            :data="detail"
            :fields="goodsMoneyFields"
            :splitLeft="7"
            class="mb-3"
          >
            <template #purchase_cost="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.purchase_cost_currency_id"
              />
            </template>
            <template #cost_of_goods="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.purchase_cost_currency_id"
              />
            </template>
            <template #purchase_cost_paid="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.purchase_cost_currency_id"
              />
            </template>
            <template #purchase_cost_unsolved="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.purchase_cost_currency_id"
              />
            </template>
            <template #purchase_cost_debited="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.purchase_cost_currency_id"
              />
            </template>
            <template #purchase_cost_outstanding="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.purchase_cost_currency_id"
              />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol sm="12" md="6" lg="6" :xl="{ size: '4', order: '3' }">
          <TTableAsForm
            title="Service charge"
            :data="detail"
            :fields="serviceChargeFields"
            :splitLeft="7"
            class="mb-3"
          >
            <template #service_fee="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
            <template #service_fee_paid="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
            <template #service_fee_discount="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
                :editable="!updating"
                @change="updateOrder('service_fee_discount', $event)"
              />
            </template>
            <template #service_fee_unsolved="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
            <template #box_service_fee="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
            <template #service_fee_debited="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
            <template #service_fee_outstanding="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol sm="12" md="6" lg="6" :xl="{ size: '4', order: '5' }">
          <TTableAsForm
            title="Other fees"
            :data="detail"
            :fields="additionalCostFields"
            :splitLeft="7"
            class="mb-3"
          >
            <template #additional_cost="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.purchase_cost_currency_id"
                :editable="!updating"
                @change="updateOrder('additional_cost', $event)"
              />
            </template>
            <template #buying_agent_fee="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
            <template #buying_agent_fee_percentage="{ value }">
              <TMessagePercent
                :value="value"
                :editable="!updating"
                @change="updateOrder('buying_agent_fee_percentage', $event)"
              />
            </template>
            <template #cod_cost="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
                :editable="!updating && detail.type_id != 'Shipment'"
                @change="updateOrder('cod_cost', $event)"
              />
            </template>
            <template #estimated_freight_charge="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol sm="12" md="6" lg="6" :xl="{ size: '4', order: '6' }">
          <TTableAsForm
            title="Compensation"
            :data="detail"
            :fields="compensationFields"
            :splitLeft="7"
            class="mb-3"
          >
            <template #compensation="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
            <template #compensated="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
            <template #compensation_unsolved="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol sm="12" md="6" lg="6" :xl="{ size: '4', order: '6' }">
          <TTableAsForm
            title="Info"
            :data="detail"
            :fields="otherFields"
            :splitLeft="7"
            class="mb-3"
          >
            <template #sales_organization_id="{ value }">
              <SMessageOrganization :id="value" />
            </template>
            <template #transport_organization_id="{ value }">
              <SMessageOrganization :id="value" />
            </template>
            <template #created_at="{ value }">
              <TMessageDateTime :content="value" small />
            </template>
            <template #updated_at="{ value }">
              <TMessageDateTime :content="value" small />
            </template>
            <template #payment_due_date="{ value }">
              <TMessageDateTime
                :editable="!updating"
                @change="updateOrder('payment_due_date', $event)"
                :content="value"
                dateOnly
              />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol sm="12" md="6" lg="6" :xl="{ size: '4', order: '6' }">
          <TTableAsForm
            title="Other"
            :data="detail"
            :fields="freightChargeFields"
            :splitLeft="7"
            class="mb-3"
          >
            <template #auto_add_contract="{ value }">
              <TSwitch
                :checked="value"
                :disabled="updating"
                @change="updateOrder('auto_add_contract', $event)"
              />
            </template>
            <template #is_pay_freight_charge_in_lading_bill="{ value }">
              <TSwitch :checked="value" disabled />
            </template>
            <template #is_pay_freight_charge_in_lading_bill-header>
              <TMessage content="Pay freight charge in lading bill" bold />
            </template>
            <template #is_allow_custom_freight_rate="{ value }">
              <TSwitch
                :checked="value"
                :disabled="updating"
                @change="updateOrder('is_allow_custom_freight_rate', $event)"
              />
            </template>
            <template #is_allow_custom_freight_rate-header>
              <TMessage
                content="Allow the use of the custom freight rate"
                bold
              />
            </template>
            <template #freight_rate="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
                :editable="!updating"
                @change="updateOrder('freight_rate', $event)"
              />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol sm="12" md="6" lg="6" :xl="{ size: '4', order: '9' }">
          <TTableAsForm
            title="Exchange"
            :data="detail"
            :fields="exchangeFields"
            :splitLeft="7"
            class="mb-3"
          >
            <template #total_payment="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="detail.service_fee_currency_id"
              />
            </template>
            <template #payment_rate="{ value }">
              <TMessageNumber
                :value="value"
                :editable="!updating"
                @change="updateOrder('payment_rate', $event)"
              />
            </template>
          </TTableAsForm>
        </CCol>
      </CRow>
      <CRow class="mb-4" v-if="!noItem">
        <CCol>
          <TTableAdvance
            :items="orderItems"
            :fields="itemFields"
            :store="`order.${orderItemStore}`"
            title="Items"
            resource
            noFilter
            removable
            creatable
            @click-create="showCreateItem = true"
          >
            <template #purchase="{ item }">
              <td>
                <div v-if="item.distributions">
                  <TMessageNotFound
                    v-if="
                      !item.distributions.length ||
                      item.distributions.every((x) => !x.purchase_item)
                    "
                  />
                  <div
                    v-for="dist in item.distributions"
                    :key="dist.id"
                    class="mb-2"
                  >
                    <CRow v-if="dist.purchase_item">
                      <CCol col="6">
                        <TLink
                          :content="dist.purchase_item.purchase_id"
                          :to="
                            lodash.getReferenceLink(
                              'purchase',
                              dist.purchase_item.purchase_id
                            )
                          "
                          :messageOptions="{ bold: true, size: 'h5' }"
                        />
                        <TMessage
                          content="Price"
                          :addClasses="['d-flex flex-wrap']"
                        >
                          <template #suffix
                            >:
                            <TMessageMoney
                              class="ml-1"
                              :amount="dist.price"
                              :currency="item.currency_id"
                            />
                          </template>
                        </TMessage>
                        <TMessage
                          content="Quantity"
                          :addClasses="['d-flex flex-wrap']"
                        >
                          <template #suffix
                            >:
                            <TMessageNumber
                              class="ml-1"
                              :messageOptions="{ bold: true }"
                              :value="dist.quantity"
                              :suffix="$t('Piece')"
                            />
                          </template>
                        </TMessage>
                      </CCol>
                      <CCol col="6" v-if="dist.purchase_item">
                        <div>
                          <TMessage content="Payment due date" bold />
                          <TMessageDateTime
                            :content="dist.purchase_item.payment_due_date"
                            dateOnly
                          />
                        </div>
                        <div>
                          <TMessage content="Expected delivery" bold />
                          <TMessageDateTime
                            :content="dist.purchase_item.expected_delivery"
                            dateOnly
                          />
                        </div>
                      </CCol>
                    </CRow>
                  </div>
                </div>
              </td>
            </template>
            <template #product="{ item }">
              <td>
                <SCardProductItems
                  :item="item.product"
                  resource="/sale/goods/products"
                  widthAuto
                />
              </td>
            </template>
            <template #quantity="{ item }">
              <td>
                <TMessageNumber
                  :value="item.quantity"
                  :editable="!orderItemLoading"
                  @change="updateOrderItem('quantity', $event)"
                />
              </td>
            </template>
            <template #price="{ item }">
              <td>
                <TMessageMoney
                  :amount="item.price"
                  :editable="!orderItemLoading"
                  @change="updateOrderItem('price', $event)"
                  :currency="item.currency_id"
                  :decimalLimit="6"
                />
              </td>
            </template>
            <template #price_with_tax="{ item }">
              <td>
                <TMessageMoney
                  :amount="item.price_with_tax"
                  :currency="item.currency_id"
                />
              </td>
            </template>
            <template #amount="{ item }">
              <td>
                <TMessageMoney
                  :amount="item.amount"
                  :currency="item.currency_id"
                />
              </td>
            </template>
            <template #balance="{ item }">
              <td>
                <TMessageMoney
                  :amount="item.balance"
                  :currency="item.currency_id"
                />
              </td>
            </template>
            <template #tax="{ item }">
              <td>
                <TMessageMoney
                  :amount="item.tax"
                  :currency="item.currency_id"
                />
              </td>
            </template>
            <template #expected_delivery="{ item }">
              <td>
                <TMessageDateTime
                  :content="item.expected_delivery"
                  dateOnly
                  showEditModal
                  :editable="!orderItemLoading"
                  @change="updateOrderItem('expected_delivery', $event)"
                />
              </td>
            </template>
            <template #tax_percent="{ item }">
              <td>
                <TMessagePercent
                  :value="item.tax_percent"
                  :editable="!orderItemLoading"
                  @change="updateOrderItem('tax_percent', $event)"
                />
              </td>
            </template>
            <template #discount_tax_per_tax_percent="{ item }">
              <td>
                <TMessagePercent
                  :value="item.discount_tax_per_tax_percent"
                  :editable="!orderItemLoading"
                  @change="
                    updateOrderItem('discount_tax_per_tax_percent', $event)
                  "
                />
              </td>
            </template>
            <template #note="{ item }">
              <td>
                <TMessageText
                  :value="item.note"
                  :editable="!orderItemLoading"
                  @change="updateOrderItem('note', $event)"
                />
              </td>
            </template>
          </TTableAdvance>
          <SModalAddItem
            :key="key"
            :show.sync="showCreateItem"
            @click-add="addItem"
            :currency_id="detail.purchase_cost_currency_id"
          />
        </CCol>
      </CRow>
      <SModalAddTracking
        v-if="
          detail.type_id == 'Shipment' &&
          detail.trackings &&
          !detail.trackings.length
        "
        :show.sync="showAddTracking"
        store="order.order_shipment"
      />
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    orderStore: {
      type: String,
      required: true,
    },
    orderItemStore: {
      type: String,
      required: true,
    },
    noItem: {
      type: Boolean,
      default: false,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      itemFields: [
        { key: "_", _style: "min-width: 40px; width: 40px" },
        {
          key: "purchase",
          label: "Purchase order",
          _style: "min-width: 330px; width: 330px",
        },
        {
          key: "product",
          label: "Product",
          _style: "min-width: 350px; width: 350px",
        },
        {
          key: "price",
          label: "Price without tax",
          _style: "min-width: 180px; width: 180px",
        },
        {
          key: "price_with_tax",
          label: "Price with tax",
        },
        {
          key: "quantity",
          label: "Quantity",
          _style: "min-width: 150px; width: 150px",
        },
        { key: "amount", label: "Amount" },
        { key: "balance", label: "Total payment" },
        { key: "tax", label: "Tax" },
        {
          key: "tax_percent",
          label: "Tax percent",
          _style: "min-width: 150px; width: 150px",
        },
        {
          key: "discount_tax_per_tax_percent",
          label: "Discount tax",
          _style: "min-width: 150px; width: 150px",
        },
        {
          key: "expected_delivery",
          label: "Desired delivery",
          _style: "min-width: 150px; width: 150px",
        },
        {
          key: "note",
          label: "Note",
          _style: "min-width: 300px; width: 300px",
        },
      ],
      detailFields: [
        { key: "customer_id", label: "Customer" },
        { key: "shipment_method_id", label: "Shipment method" },
        { key: "note", label: "Note" },
        { key: "special_declaration", label: "Special declaration" },
        { key: "special_goods_fee", label: "Special goods fee" },
        { key: "insurance_declaration", label: "Insurance declaration" },
        { key: "insurance_fee", label: "Insurance fee" },
      ],
      requestFields: [
        { key: "repackage", label: "Repackage" },
        { key: "merge_package", label: "Merge package" },
      ],
      goodsMoneyFields: [
        { key: "purchase_cost", label: "Total amount" },
        { key: "cost_of_goods", label: "Goods money" },
        { key: "purchase_cost_paid", label: "Paid" },
        { key: "purchase_cost_outstanding", label: "Outstanding" },
        { key: "purchase_cost_debited", label: "Debited" },
        { key: "purchase_cost_unsolved", label: "Unsolved" },
      ],
      serviceChargeFields: [
        { key: "service_fee", label: "Service charge" },
        { key: "box_service_fee", label: "Service fee boxes" },
        { key: "service_fee_discount", label: "Service fee discount" },
        { key: "service_fee_paid", label: "Paid" },
        { key: "service_fee_outstanding", label: "Outstanding" },
        { key: "service_fee_debited", label: "Debited" },
        { key: "service_fee_unsolved", label: "Unsolved" },
      ],
      additionalCostFields: [
        { key: "additional_cost", label: "Additional cost" },
        { key: "cod_cost", label: "Domestic delivery fee" },
        { key: "estimated_freight_charge", label: "Estimated freight charge" },
        { key: "buying_agent_fee", label: "Purchase surcharge" },
        {
          key: "buying_agent_fee_percentage",
          label: "Purchase surcharge percentage",
        },
      ],
      exchangeFields: [
        { key: "payment_rate", label: "Exchange rate" },
        { key: "total_payment", label: "Total payment" },
      ],
      compensationFields: [
        { key: "compensation", label: "Commodity compensation" },
        { key: "compensated", label: "Compensated" },
        { key: "compensation_unsolved", label: "Uncompensated" },
      ],
      otherFields: [
        { key: "sales_organization_id", label: "Purchasing company" },
        { key: "transport_organization_id", label: "Transportation company" },
        { key: "created_at", label: "Created at" },
        { key: "updated_at", label: "Latest update" },
        { key: "payment_due_date", label: "Payment due date" },
      ],
      showCreateItem: false,
      key: "add-item",
      showConfirmDetachTracking: false,
      showAddTracking: false,
      showDebitConfirm: false,
    };
  },
  watch: {
    noItem: {
      immediate: true,
      handler(value) {
        if (value) {
          this.detailFields.splice(2, 0, {
            key: "trackings",
            label: "Tracking",
          });
        }
      },
    },
  },
  computed: {
    detail() {
      return this.$store.getters[`order.${this.orderStore}.detail`];
    },
    updating() {
      return this.$store.getters[`order.${this.orderStore}.detail.updating`];
    },
    detailLoading() {
      return this.$store.getters[`order.${this.orderStore}.detail.loading`];
    },
    orderItems() {
      return !this.noItem
        ? this.$store.getters[`order.${this.orderItemStore}.list`]
        : false;
    },
    orderItemLoading() {
      return !this.noItem
        ? this.$store.getters[`order.${this.orderItemStore}.detail.loading`]
        : false;
    },
    orderItemCreating() {
      return !this.noItem
        ? this.$store.getters[`order.${this.orderItemStore}.creating`]
        : false;
    },
    id() {
      return this.$route.params.id;
    },
    loading() {
      return (
        this.orderItemLoading || this.orderItemCreating || this.detailLoading
      );
    },
    freightChargeFields() {
      const is_allow_custom_freight_rate = {
        key: "is_allow_custom_freight_rate",
        label: "Allow the use of the custom freight rate",
      };
      const freight_rate = {
        key: "freight_rate",
        label: "Freight rate",
      };
      return [
        { key: "auto_add_contract", label: "Auto add contract" },
        {
          key: "is_pay_freight_charge_in_lading_bill",
          label: "Pay freight charge in lading bill",
        },
        !this.detail.is_pay_freight_charge_in_lading_bill
          ? is_allow_custom_freight_rate
          : {},
        !this.detail.is_pay_freight_charge_in_lading_bill &&
        this.detail.is_allow_custom_freight_rate
          ? freight_rate
          : {},
      ].filter((value) => Object.keys(value).length !== 0);
    },
  },
  methods: {
    refreshItems() {
      this.$store.dispatch(`order.${this.orderItemStore}.fetch`);
    },
    refreshOrder() {
      this.$store.dispatch(`order.${this.orderStore}.detail.fetch`, this.id);
    },
    addItem(item) {
      const data = {
        ...this.lodash.omit(item, ["product", "is_price_with_tax"]),
        order_id: this.id,
      };
      this.$store
        .dispatch(
          `order.${this.orderItemStore}.create`,
          this.lodash.pickBy(data, this.lodash.identity)
        )
        .then(() => {
          this.showCreateItem = false;
          this.reset();
          this.refreshItems();
        });
    },
    refresh() {
      this.refreshOrder();
      if (!this.noItem) this.refreshItems();
    },
    reset() {
      this.key = this.lodash.resetKey("add-item");
    },
    debit() {
      this.$store
        .dispatch(`order.${this.orderStore}.detail.update`, {
          action_debit: true,
        })
        .then(() => {
          this.refreshOrder();
        });
    },
    nextStatusDebited() {
      this.updateOrder("next_status", "Debited");
    },
    updateOrder(field, value) {
      return this.$store.dispatch(`order.${this.orderStore}.detail.update`, {
        [field]: value,
      });
    },
    updateOrderItem(field, value) {
      this.$store.dispatch(`order.${this.orderItemStore}.detail.update`, {
        [field]: value,
      });
    },
    updateTracking(id, value) {
      this.$store.dispatch(`order.trackings.update`, {
        id,
        attributes: {
          code: value,
        },
      });
    },
    detachTracking(id) {
      this.$store
        .dispatch("order.order_shipment.detail.detach-tracking", {
          tracking_ids: [id],
        })
        .then(() => {
          this.refreshOrder();
        });
    },
  },
  destroyed() {
    this.$store.commit("order." + this.orderStore + ".detail.purge");
  },
};
</script>
